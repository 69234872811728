
.information-page {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../public/home_page_image.jpg');
    width: 100%;
    height: 800px;
    background-position: center; 
    background-size: cover; 
}

.information-content {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white; 
    height: 200px;
    width: 500px;
    border-radius: 10px;
    color: #404040;
}

.information-title {
    font-size: 20px;
    height: 50px;
}

.information-text {
    font-size: 16px;
    font-weight: 300;
    height: 50px;
    width: 400px;
}